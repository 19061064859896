

    import { defineComponent } from 'vue'
    import Agoda from '../components/Logos/Agoda.vue'
    import Disney from '../components/Logos/Disney.vue'
    import Dyson from '../components/Logos/Dyson.vue'
    import EF from '../components/Logos/EF.vue'
    import Epson from '../components/Logos/Epson.vue'
    import Hublot from '../components/Logos/Hublot.vue'
    import Ikea from '../components/Logos/Ikea.vue'
    import Kaspersky from '../components/Logos/Kaspersky.vue'
    import Lenovo from '../components/Logos/Lenovo.vue'
    import LVMH from '../components/Logos/LVMH.vue'
    import Microsoft from '../components/Logos/Microsoft.vue'
    import Nike from '../components/Logos/Nike.vue'
    import Porsche from '../components/Logos/Porsche.vue'
    import PWC from '../components/Logos/PWC.vue'
    import UBS from '../components/Logos/UBS.vue'
    import Vice from '../components/Logos/Vice.vue'

    export default defineComponent({
        name: 'LogoWall',
        components: {
            Microsoft,
            Hublot,
            Nike,
            UBS,
            LVMH,
            Ikea,
            PWC,
            Disney,
            Vice,
            Agoda,
            Kaspersky,
            Porsche,
            Epson,
            Lenovo,
            Dyson,
            EF
        }
    })

