import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "logo-wall space-y-12 md:space-y-20" }
const _hoisted_2 = { class: "logo-wall__row" }
const _hoisted_3 = { class: "logo-wall__column" }
const _hoisted_4 = { class: "logo-wall__logo" }
const _hoisted_5 = { class: "logo-wall__logo" }
const _hoisted_6 = { class: "logo-wall__column" }
const _hoisted_7 = { class: "logo-wall__logo" }
const _hoisted_8 = { class: "logo-wall__logo" }
const _hoisted_9 = { class: "logo-wall__row" }
const _hoisted_10 = { class: "logo-wall__column" }
const _hoisted_11 = { class: "logo-wall__logo" }
const _hoisted_12 = { class: "logo-wall__logo" }
const _hoisted_13 = { class: "logo-wall__column" }
const _hoisted_14 = { class: "logo-wall__logo" }
const _hoisted_15 = { class: "logo-wall__logo" }
const _hoisted_16 = { class: "logo-wall__row" }
const _hoisted_17 = { class: "logo-wall__column" }
const _hoisted_18 = { class: "logo-wall__logo" }
const _hoisted_19 = { class: "logo-wall__logo" }
const _hoisted_20 = { class: "logo-wall__column" }
const _hoisted_21 = { class: "logo-wall__logo" }
const _hoisted_22 = { class: "logo-wall__logo" }
const _hoisted_23 = { class: "logo-wall__row" }
const _hoisted_24 = { class: "logo-wall__column" }
const _hoisted_25 = { class: "logo-wall__logo" }
const _hoisted_26 = { class: "logo-wall__logo" }
const _hoisted_27 = { class: "logo-wall__column" }
const _hoisted_28 = { class: "logo-wall__logo" }
const _hoisted_29 = { class: "logo-wall__logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Microsoft = _resolveComponent("Microsoft")!
  const _component_Hublot = _resolveComponent("Hublot")!
  const _component_Nike = _resolveComponent("Nike")!
  const _component_Ikea = _resolveComponent("Ikea")!
  const _component_UBS = _resolveComponent("UBS")!
  const _component_Disney = _resolveComponent("Disney")!
  const _component_PWC = _resolveComponent("PWC")!
  const _component_Dyson = _resolveComponent("Dyson")!
  const _component_LVMH = _resolveComponent("LVMH")!
  const _component_Lenovo = _resolveComponent("Lenovo")!
  const _component_Porsche = _resolveComponent("Porsche")!
  const _component_Agoda = _resolveComponent("Agoda")!
  const _component_Kaspersky = _resolveComponent("Kaspersky")!
  const _component_Epson = _resolveComponent("Epson")!
  const _component_Vice = _resolveComponent("Vice")!
  const _component_EF = _resolveComponent("EF")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Microsoft)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Hublot)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Nike)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Ikea)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_UBS)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_Disney)
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_PWC)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_Dyson)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_LVMH)
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_Lenovo)
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createVNode(_component_Porsche)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createVNode(_component_Agoda)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _createVNode(_component_Kaspersky)
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createVNode(_component_Epson)
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createVNode(_component_Vice)
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createVNode(_component_EF)
        ])
      ])
    ])
  ]))
}