import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "md:pr-10" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "text-xl font-mabry-pro" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_LogoWall = _resolveComponent("LogoWall")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Green
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PageHeader, {
          smaller: "",
          class: "page-header mt-32 lg:mt-48",
          header: _ctx.cms?.page_header_heading,
          "header-span": 5,
          "sub-header": _ctx.cms?.page_header_content,
          "use-new-link-style": ""
        }, null, 8, ["header", "sub-header"]),
        _createVNode(_component_Grid, {
          fluid: "",
          class: "mt-12 md:pb-24"
        }, {
          default: _withCtx(() => [
            (_ctx.cms?.asset_1?.desktop_asset)
              ? (_openBlock(), _createBlock(_component_Picture, {
                  key: 0,
                  image: _ctx.cms?.asset_1?.desktop_asset,
                  width: '100%',
                  height: _ctx.cms?.asset_1?.size.value === 'fullsize' ? '100%' : [ 220, 440 ],
                  maxWidth: _ctx.cms?.asset_1?.size.value === 'fullsize' ? null : '100%',
                  cover: "",
                  class: "about__video"
                }, null, 8, ["image", "height", "maxWidth"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_Grid, { class: "py-24 md:pt-24 md:pb-48" }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              span: [ 2, 4 ],
              push: [ 0, 1 ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Heading, {
                  tag: "h2",
                  innerHTML: _ctx.cms.heading_1
                }, null, 8, ["innerHTML"]),
                _createVNode(_component_Paragraph, {
                  tag: "div",
                  class: "mt-32 text-gray-600",
                  smaller: "",
                  innerHTML: _ctx.cms.content_1
                }, null, 8, ["innerHTML"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              span: [ 2, 6 ],
              class: "py-12 md:py-48"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Slider, {
                  images: _ctx.sliderMedia,
                  sizes: _ctx.sizes
                }, null, 8, ["images", "sizes"])
              ]),
              _: 1
            }),
            _createVNode(_component_AOS, {
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_Heading, {
                        tag: "h2",
                        class: "text-black",
                        innerHTML: _ctx.cms?.values_section_title
                      }, null, 8, ["innerHTML"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_AOS, {
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cms?.values_section_descriptions, (item, index) => {
                  return (_openBlock(), _createBlock(_component_Column, {
                    key: index,
                    span: _ctx.cms?.values_section_style?.value ?? 2,
                    class: _normalizeClass([ index === 0 ? 'sm:mt-10 mt-20' : 'mt-20' ])
                  }, {
                    default: _withCtx(() => [
                      (item?.title)
                        ? (_openBlock(), _createBlock(_component_Heading, {
                            key: 0,
                            tag: "h3",
                            "less-margin": ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Paragraph, {
                        tag: "div",
                        class: "text-gray-600 md:pr-10",
                        smaller: "",
                        innerHTML: item?.description
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 2
                  }, 1032, ["span", "class"]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              span: [ 2, 4 ],
              push: [ 0, 1 ],
              class: "pt-36"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Heading, {
                  tag: "h2",
                  innerHTML: _ctx.cms.heading_2
                }, null, 8, ["innerHTML"]),
                _createVNode(_component_Paragraph, {
                  tag: "div",
                  class: "mt-12 text-gray-600",
                  smaller: "",
                  innerHTML: _ctx.cms.content_2
                }, null, 8, ["innerHTML"])
              ]),
              _: 1
            }),
            _createVNode(_component_AOS, {
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  span: [ 2, 4 ],
                  push: [ 0, 1 ],
                  class: "pt-36"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Heading, {
                      tag: "h2",
                      innerHTML: _ctx.cms.leadership_section_title
                    }, null, 8, ["innerHTML"]),
                    _createVNode(_component_Paragraph, {
                      tag: "div",
                      class: "mt-32 text-gray-600",
                      smaller: "",
                      innerHTML: _ctx.cms.leadership_section_description
                    }, null, 8, ["innerHTML"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_AOS, {
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cms?.leadership_section_leaderships, (item, index) => {
                  return (_openBlock(), _createBlock(_component_Column, {
                    key: 'leadership' + index,
                    span: [ 2, 3, 2, 2 ],
                    class: "pt-24"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", null, [
                          _createVNode(_component_Picture, {
                            class: "about__leadership-image",
                            image: item?.asset[0],
                            cover: "",
                            auto: "",
                            center: false
                          }, null, 8, ["image"])
                        ]),
                        _createVNode(_component_Heading, {
                          tag: "h3",
                          "less-margin": "",
                          class: "pt-24"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item?.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", null, [
                          _createVNode(_component_Paragraph, {
                            class: "text-gray-700 font-bold md:pr-10 my-4",
                            smaller: "",
                            innerHTML: item?.role
                          }, null, 8, ["innerHTML"]),
                          _createVNode(_component_Paragraph, {
                            tag: "div",
                            class: "text-gray-400 md:pr-10",
                            smaller: "",
                            innerHTML: item?.description
                          }, null, 8, ["innerHTML"])
                        ]),
                        (item?.link[0]?.label)
                          ? (_openBlock(), _createBlock(_component_Anchor, {
                              key: 0,
                              class: "mt-7",
                              route: item?.link[0]?.target,
                              external: "",
                              smaller: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item?.link[0]?.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["route"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_AOS, {
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  span: [ 2, 6 ],
                  class: "pt-32"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_Heading, {
                        tag: "h2",
                        class: "text-black",
                        innerHTML: _ctx.cms?.services_section_title
                      }, null, 8, ["innerHTML"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_AOS, {
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cms?.services_section_descriptions, (item, index) => {
                  return (_openBlock(), _createBlock(_component_Column, {
                    key: index,
                    span: _ctx.cms?.services_section_style?.value ?? 2,
                    class: "pt-24"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Heading, {
                        tag: "h3",
                        "less-margin": ""
                      }, {
                        default: _withCtx(() => [
                          (item.type === 'link_and_description' && (item.entry || item.target))
                            ? (_openBlock(), _createBlock(_component_Anchor, {
                                key: 0,
                                external: !item.internal,
                                route: item.internal ? { name: _ctx.Route.Services, params: { slug: item?.entry?.slug ?? '' } } : item.target
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_4, _toDisplayString(item?.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["external", "route"]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item?.title), 1))
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_Paragraph, {
                        tag: "div",
                        class: "text-gray-600 md:pr-10",
                        smaller: "",
                        innerHTML: item?.description
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 2
                  }, 1032, ["span"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_Grid, {
          fluid: "",
          class: "pb-24"
        }, {
          default: _withCtx(() => [
            (_ctx.cms?.asset_3?.desktop_asset)
              ? (_openBlock(), _createBlock(_component_Picture, {
                  key: 0,
                  image: _ctx.cms?.asset_3?.desktop_asset,
                  width: '100%',
                  height: _ctx.cms?.asset_3?.size.value === 'fullsize' ? '100%' : [ 220, 440 ],
                  maxWidth: _ctx.cms?.asset_3?.size.value === 'fullsize' ? null : '100%',
                  cover: "",
                  class: "about__video"
                }, null, 8, ["image", "height", "maxWidth"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["to"]),
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Dark
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Grid, { class: "md:py-48" }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              span: [ 2, 4 ],
              push: [ 0, 1 ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Heading, {
                  tag: "h2",
                  innerHTML: _ctx.cms.heading_3
                }, null, 8, ["innerHTML"]),
                _createVNode(_component_Paragraph, {
                  class: "mt-12 text-gray-600",
                  smaller: "",
                  innerHTML: _ctx.cms.content_3
                }, null, 8, ["innerHTML"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_Grid, { class: "sm:mt-16" }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, { span: [ 2, 6 ] }, {
              default: _withCtx(() => [
                _createVNode(_component_Paragraph, { heading: "" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.cms.related_clients_label), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_LogoWall, { class: "mt-20" })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.cms.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.cms.seo,
          "structured-data": _ctx.cms.seo_structured_data,
          published: _ctx.cms.date,
          modified: _ctx.cms.last_modified
        }, null, 8, ["seo", "structured-data", "published", "modified"]))
      : _createCommentVNode("", true)
  ], 64))
}